
export const ExpHeadData = [
    {
        title:"订单量",
        value:"0",
        width:"22",
        after:true
    },{
        title:"总付款",
        value:"35",
        width:"22",
        after:true
    },{
        title:"配送工资",
        value:"0",
        width:"22",
        after:true
    },{
        title:"校区收益",
        value:"0",
        width:"22",
        after:true
    },{
        title:"平台收益",
        value:"0",
        width:"22",
        after:true
    },{
        title:"微信抽成",
        value:"0",
        width:"22",
        after:false
    },
]

export const ShopHeadData = [
    {
        title:"订单量",
        value:"0",
        width:"22",
        after:true
    },{
        title:"总付款",
        value:"0",
        width:"22",
        after:true
    },{
        title:"店铺收入",
        value:"0",
        width:"22",
        after:true
    },{
        title:"配送工资",
        value:"0",
        width:"22",
        after:true
    },{
        title:"校区收益",
        value:"0",
        width:"22",
        after:true
    },{
        title:"平台收益",
        value:"0",
        width:"22",
        after:true
    },{
        title:"微信抽成",
        value:"0",
        width:"22",
        after:false
    },
]

export const headData = (state="exp") => state =="exp" ? ExpHeadData : ShopHeadData

export const TitleList = [
    {
        width: "25",
        value: "配送员",
        after: true
    }, {
        width: "25",
        value: "单量",
        after: true
    }, {
        width: "25",
        value: "单价",
        after: true
    }, {
        width: "25",
        value: "工资",
        after: false
    }
]


export const testList = [
    [
        {
            value: "无",
        }, {
            value: "0",
        }, {
            value: "0",
        }, {
            value: "0",
        },
    ]
]
