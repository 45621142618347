

import Axios from "../Config/shop"
import {
    HeadType,
    BodyType,
    getUserCountType,
    getSchoolMonthUserCountType,
    getBikeIncomeType
} from "@/Api/Platform/interfaceType"

// 查询 日 收益
export function getBikeIncome( headers:HeadType,body:getBikeIncomeType ):Promise<any>{
    return (
        Axios({
            url:"/dispatch/riderWageList",
            method:"POST",
            headers,
            data:body
        })
    )
}
