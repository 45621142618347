
import {getBikeIncome} from "@/Api/Platform/bike";
import Storage from "@/util/Storage";
import {filterToArray} from "@/views/PlatformAdmin/Util";

// 查看骑手收入
export function getTodayExpIncome(body:any) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");

    return Promise.resolve(getBikeIncome({ userId,token },body).then(res=>{
        return {
            rawList:res.data,
            list:filterToArray(res.data,"0","userName","orderCount","commission","salary",)
        }
    }))
}

